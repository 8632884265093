import React from 'react'

const initialState = {
	pageBg: 'bgColor',
	updateState: () => {},
}

export const PageContext = React.createContext(initialState)

class PageProvider extends React.Component {
	state = initialState

	updateState = (key, value) => {
		this.setState({[key]:value})
	}

	render () {
		const { updateState } = this

		return (
			<PageContext.Provider value={{ ...this.state, updateState }}>{this.props.children}</PageContext.Provider>
		)
	}
}

export const withPageContext = (Component) => {
	return props => (
		<PageContext.Consumer>{(context) => (<Component {...props} pageContext={context}/>)}</PageContext.Consumer>
	)
}

export default PageProvider