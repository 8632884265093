import * as mq from './mediaQueries'
import { rgba } from 'polished'
import * as colors from './colors'
import * as util from './util'

import './fonts'

// Place global Typography in this file
export const bodyFont = `Plain, -apple-system, serif`
export const headlineFont = `Editorial, Times New Roman, -apple-system, serif`

export const responsiveStyles = util.responsiveStyles

export const bodyLarge = `
	${ responsiveStyles('font-size', 36, 30, 26, 24) }
	line-height: 1.333em;
	font-family: ${ headlineFont };
	letter-spacing: -.01em;
	text-transform: none;
	font-weight: normal;
`
export const bodyMedium = `
	${ responsiveStyles('font-size', 20, 18, 16, 14) }
	line-height: 1.666em;
	font-family: ${ bodyFont };
	letter-spacing: -.01em;
	text-transform: none;
	font-weight: normal;
`
export const body = `
	${ responsiveStyles('font-size', 18, 16, 14, 14) }
	line-height: 1.6em;
	font-family: ${ bodyFont };
	letter-spacing: -.01em;
	text-transform: none;
	font-weight: normal;
`
export const bodySmall = `
	${ responsiveStyles('font-size', 16, 14, 14, 14) }
	line-height: 1.6em;
	font-family: ${ bodyFont };
	letter-spacing: -.01em;
	text-transform: none;
	font-weight: normal;
`

export const h1 = `
	${ responsiveStyles('font-size', 160, 110, 80, 46) }
	line-height: 1.18em;
	font-family: ${ headlineFont };
	font-weight: normal;
	letter-spacing: -.015em;
	text-transform: none;
`

export const h2 = `
	${ responsiveStyles('font-size', 85, 70, 56, 42) }
	line-height: 1.25em;
	font-family: ${ headlineFont };
	font-weight: normal;
	letter-spacing: -.015em;
	text-transform: none;
`

export const h3 = `
	${ responsiveStyles('font-size', 64, 50, 36, 28) }
	line-height: 1.25em;
	font-family: ${ headlineFont };
	font-weight: normal;
	letter-spacing: -.015em;
	text-transform: none;
`

export const h4 = `
	${ responsiveStyles('font-size', 48, 38, 30, 24) }
	line-height: 1.25em;
	font-family: ${ headlineFont };
	font-weight: normal;
	letter-spacing: -.015em;
	text-transform: none;
`

export const h5 = `
	${ responsiveStyles('font-size', 22, 20, 18, 16) }
	font-family: ${ bodyFont };
	font-weight: 700;
	line-height: 1.25em;
	letter-spacing: -.015em;
	text-transform: none;
`

export const h6 = `
	${ responsiveStyles('font-size', 16, 14, 14, 13) }
	font-family: ${ bodyFont };
	font-weight: 600;
	line-height: 1.25em;
	letter-spacing: .1em;
	text-transform: uppercase;
`

export const blockquote = `
	${ bodyLarge }
`

export const eyebrow = `
	${ h6 }
	color: ${ colors.blue };
`

export const buttonStyle = `
	${ body }
	line-height: 1em;
	font-weight: 600;
`

export const storyNotes = `
	max-width: 750px;
	p {
		code {
			background: ${ rgba(colors.textColor, 0.1) };
			color: ${ colors.textColor };
			border-radius: 3px;
			padding: .05em .35em .15em;
			font-style: normal;
		}
	}
`
