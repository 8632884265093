import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import EditorialWoff from '../assets/fonts/editorial/editorial-new-regular.woff'
import EditorialWoff2 from '../assets/fonts/editorial/editorial-new-regular.woff2'
import EditorialTtf from '../assets/fonts/editorial/lig-test/editorial-new-regular.ttf'

import PlainWoff from '../assets/fonts/plain/plain-regular.woff'
import PlainWoff2 from '../assets/fonts/plain/plain-regular.woff2'
import PlainOtf from '../assets/fonts/plain/plain-regular.otf'

import PlainMediumWoff from '../assets/fonts/plain/plain-medium.woff'
import PlainMediumWoff2 from '../assets/fonts/plain/plain-medium.woff2'
import PlainMediumOtf from '../assets/fonts/plain/plain-medium.otf'

import PlainBoldWoff from '../assets/fonts/plain/plain-bold.woff'
import PlainBoldWoff2 from '../assets/fonts/plain/plain-bold.woff2'
import PlainBoldOtf from '../assets/fonts/plain/plain-bold.otf'

export const fontFace = ({
  fontName,
  woff,
  woff2,
  ttf,
  otf,
  fontWeight = 'normal',
  fontStyle = 'normal',
}) => {
  let src = '';
  if (woff) src += `url('${woff}') format('woff'), `;
  if (woff2) src += `url('${woff2}') format('woff2'), `;
  if (ttf) src += `url('${ttf}') format('ttf'), `;
  if (otf) src += `url('${otf}') format('otf'), `;
  if (src && src.length && src.slice(-2) === ', ') src = src.slice(0, -2);
  return (`
    @font-face {
      font-family: '${fontName}';
      src: ${src};
      font-weight: ${fontWeight};
      font-style: ${fontStyle};
    }
`);
};

export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Editorial = 'Editorial'
export const EditorialFont = fontFace({
	fontName: Editorial,
  woff: EditorialWoff,
  woff2: EditorialWoff2,
  // ttf: EditorialTtf
})

export const Plain = 'Plain'
export const PlainFont = fontFace({
  fontName: Plain,
  woff: PlainWoff,
  woff2: PlainWoff2,
  otf: PlainOtf
})
export const PlainItalicFont = fontFace({
  fontName: Plain,
  woff: PlainMediumWoff,
  woff2: PlainMediumWoff2,
  otf: PlainMediumOtf,
  fontWeight: 600
})
export const PlainBoldFont = fontFace({
  fontName: Plain,
  woff: PlainBoldWoff,
  woff2: PlainBoldWoff2,
  otf: PlainBoldOtf,
  fontWeight: 700
})
