import { lighten, darken, rgba } from 'polished'

export const black = '#000'
export const white = '#fff'

// Site Specific Colors
// export const blue = '#0000B4'
export const blue = '#020093'
export const cream = '#F0F0EE'
export const red = '#E22121'
export const yellow = '#F7D031'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = cream
export const mainColor = blue
export const alert = red
export const notify = yellow
export const success = 'green'
export const textColor = black
export const lightTextColor = rgba(textColor, 0.4)
export const lightGrey = darken(0.07, bgColor)
export const hrColor = rgba(textColor, 0.1)

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
