import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import globalStyles from 'src/styles/globalStyles'
import PageTransition from 'src/components/PageTransition'
import { Global, css } from '@emotion/core'
import { colors } from 'src/styles'
import NavContext from 'src/contexts/navContext'
import PageContext from 'src/contexts/pageContext'
import ScrollListener from 'src/components/ScrollListener'
import IntersectionObserverPolyfill from 'src/components/IntersectionObserverPolyfill'
import { ParallaxProvider } from 'react-scroll-parallax'
import './reset.css'

class Layout extends React.Component {
	showNav = () => {
    this.setState({ navVisible: true })
  }

  hideNav = () => {
    this.setState({ navVisible: false })
  }

  state = {
    navVisible: true,
    showNav: this.showNav,
    hideNav: this.hideNav,
    overlayColor: colors.bgColor
  }

  setOverlayColor = color => {
  	this.setState({ overlayColor: color })
  }

	render () {
		const { children, location } = this.props
		const { navVisible, overlayColor } = this.state

		return (
			<StaticQuery
				query={graphql`
					query SiteTitleQuery {
						site {
							siteMetadata {
								title
							}
						}
					}
				`}
				render={data => (
					<Fragment>
						<ParallaxProvider>
						<PageContext>
						<NavContext.Provider value={this.state}>
	            <ScrollListener>
								<Global
									styles={css`${ globalStyles }`}
								/>
								<IntersectionObserverPolyfill>
									<PageTransition
										location={location}
										navVisible={navVisible}
										overlayColor={overlayColor}
									>
										{children}
									</PageTransition>
								</IntersectionObserverPolyfill>
							</ScrollListener>
						</NavContext.Provider>
						</PageContext>
						</ParallaxProvider>
					</Fragment>
				)}
			/>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
