import React from 'react'

const initialState = {}

const NavContext = React.createContext(initialState)

export const withNavContext = Component => {
  return props => (
    <NavContext.Consumer>
      {context => <Component {...props} NavContext={context} />}
    </NavContext.Consumer>
  )
}

export default NavContext
